import { bool, string } from "prop-types";

import { Button } from "react-bootstrap";
import Clickable from "./clickable";
import Link from "next/link";

const CTA = ({ lg, children }) => {
  CTA.propTypes = {
    lg: bool,
    children: string,
  };

  return (
    <>
      <Link
        prefetch={false}
        href={{
          pathname: "/commencer",
        }}
        passHref
      >
        <a className="text-decoration-none d-flex">
          <Clickable force={2} className="my-auto mx-1 w-100">
            <Button
              style={{
                textTransform: "uppercase",
                fontWeight: "500",
                letterSpacing: ".02rem",
              }}
              variant="orange2"
              className={
                lg
                  ? "btn-lg w-100 btn text-primary fw-bold buttonSora"
                  : "my-auto mx-1 text-primary fw-bold buttonSora"
              }
            >
              {children}
            </Button>
          </Clickable>
        </a>
      </Link>
    </>
  );
};

export default CTA;
