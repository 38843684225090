import Cookies from "js-cookie";
import axios from "axios";

const getToken = () => {
  return new Promise((resolve, reject) => {
    const api = axios.create({
      baseURL: process.env.NEXT_PUBLIC_AUTH_URL,
    });

    const client_id = process.env.NEXT_PUBLIC_CLIENT_ID_AUTH0;

    const access = Cookies.get("cherpasAccess");
    const refresh = Cookies.get("cherpasRefresh");

    if (access) {
      resolve(access);
    } else if (refresh) {
      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      const params = new URLSearchParams();
      params.append("client_id", client_id);
      params.append("refresh_token", refresh);
      params.append("grant_type", "refresh_token");
      api
        .post("token/", params, config)
        .then((response) => {
          Cookies.set("cherpasRefresh", response.data.refresh_token, {
            expires: 365,
          });
          var date = new Date();
          date.setTime(date.getTime() + response.data.expires_in * 1000);
          Cookies.set("cherpasAccess", response.data.access_token, {
            expires: date,
          });
          resolve(response.data.access_token);
        })
        .catch(() => {
          Cookies.remove("cherpasAccess");
          reject();
        });
    } else {
      reject();
    }
  });
};

export default getToken;
